.nav {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    color: #c9c7c7;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #242424;
    @media screen and (max-width: 782px) {
        justify-content: flex-start;
    }

    &__container {
        max-width: 75%;
        display: flex;
        justify-content: center;
        align-items: center;

        .nav__menu {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .nav__link {
                padding: 0 1rem;
                font-size: 1.2rem;
                cursor: pointer;
                &:hover {
                    color: #F06000;
                    transition: all 0.3s ease-out;
                }
            }
        }
        @media screen and (max-width: 782px) {
            position: absolute;
            top: 80px;
            transform: translateX(-100%);
            width: 100%;
            height: 100vh;
            display: flex;
            align-items: flex-start;
            transform: translateX(-100%);
            border-bottom: 0.1px solid #383838;
            .nav__menu {
                flex-direction: column;
                display: table;
            
                .nav__link {
                    padding: 2rem;
                    font-size: 2rem;
                    font-weight: bold;
                    border-bottom: 0.1px solid #383838;
                    &:hover {
                        background-color: #F06000;
                        color: white;
                        transition: all 0.3s ease-out;
                    }
                }

                &:last-child {
                    border-bottom: 0.1px solid #383838;
                }

                
            }
        }
    }
    .active {
        background-color: #242424;;
        transform: translateX(0);
        opacity: 1;
        transition: all 0.6s ease-in-out;
    }
    &__icon {
        display: none;
        margin-left: 2rem;
        cursor: pointer;
        @media screen and (max-width: 782px) {
            display: block;
        }
    }
}
.contact-me {
    width: 100%;
    height: 800px;
    background: #303030;
    overflow: hidden;
    position: relative;
    
    #tsparticles {
        height: 100%;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 96px;
        padding-bottom: 66px;

        .container {
            margin: 0 auto;
            height: 500px;
            width: 95%;
            max-width: 1200px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            @media screen and (max-width: 1036px) {
                grid-template-columns: 1fr;
            }

            .form-area {
                padding: 1rem;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                .row {
                    input {
                        display: inline-block;
                        padding: 10px 12px;
                        margin: 5px;
                        background-image: none;
                        background-clip: padding-box;
                        border: 1px solid #c9c7c7;
                        border-radius: 3px;
                        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                        background-color: transparent;
                        color: #c9c7c7;
                    }
                    textarea {
                        display: block;
                        width: 100%;
                        height: 100%;
                        padding: 10px 12px;
                        margin: 5px;
                        background-image: none;
                        background-clip: padding-box;
                        border: 1px solid #c9c7c7;
                        border-radius: 3px;
                        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                        background-color: transparent;
                        color: #c9c7c7;
                        resize: none;
                    }
                    .submit {
                        width: 100%;
                        display: inline-block;
                        padding: 10px 12px;
                        margin: 5px;
                        background-image: none;
                        background-clip: padding-box;
                        border: 1px solid #c9c7c7;
                        border-radius: 3px;
                        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                        background-color: transparent;
                        color: #c9c7c7;

                        &:hover {
                            background-color: #F06000;
                            border: 1px solid transparent;
                            outline: none;
                            color: white;
                            transition: all 0.3s ease-out;
                        }
                    }
                    @media screen and (max-width: 1128px) {
                        input {
                            width: 100%;
                        }
                    }
                }
            }

            .social-media {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .section-title {
                    width: 100%;
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: white;
                    margin-bottom: 2rem;

                    h2 {
                        display: inline;
                        border-bottom: 4px solid #F06000;
                        margin-bottom: 1rem;
                        padding-bottom: 0.5rem;
                    }

                    h5 {
                        font-size: 1.2rem;
                        color: #c9c7c7;
                        margin: 2rem 0 1rem;
                        @media screen and (max-width: 663px) {
                            display: none;
                        }
                    }
                    @media screen and (max-width: 780px) {
                        text-align: center;
                    }
                }
                .twitter-feed {
                    width: 100%;
                }
                @media screen and (max-width: 1036px) {
                    align-items: center;
                    text-align: center;
                    
                    .twitter-feed {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
                @media screen and (max-width: 663px) {
                    .twitter-feed {
                        display: none;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1036px) {
        height: 1300px;
    }
    @media screen and (max-width: 663px) {
        height: 1000px;
    }
}
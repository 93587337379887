.modal {
    &-header {
        text-align: center;
    }

    &-body {
        img {
            width: 100%;
            padding-bottom: 1rem;
        }
    }
    
    &-footer {
        .btn-primary {
            background-color: #F06000 !important;
            color: white;
            border: none;
        }
    }
}
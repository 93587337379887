#about-me {
    background: #2B2B2B;
    padding-top: 96px;
    padding-bottom: 66px;
    overflow: hidden;
    position: relative;
    min-height: 550px;
    #tsparticles {
        position: absolute !important;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding-top: 96px;
        padding-bottom: 66px;

        .container {
            margin: 0 auto;
            width: 95%;
            max-width: 1024px;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .image-area {
                padding: 0 1.2rem;
                width: 25%;
                height: 100%;
                display: flex;
                align-items: flex-start;
                img {
                    position: relative;
                    width: 150px;
                    height: 150px;
                    border-radius: 100%;
                    border: 3px solid #F06000;
                }
                @media screen and (max-width: 727px) {
                    display: none;
                }
            }
    
            .text-area {
                width: 75%;
                padding: 0 1.2rem;
                display: flex;
                flex-direction: column;
    
                .title {
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: white;
                    margin: 1rem 0;
                }
    
                .about-me-text {
                    padding: 0 1.2rem;
    
                    .main-text {
                        font-size: 1rem;
                        color: #c9c7c7;
                        margin-bottom: 1rem;
                    }
                    @media screen and (max-width: 727px) {
                        border-bottom: 1px solid #474747 ;
                    }
                }
    
                .contact-details {
                    padding: 0 1.2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    .details {
                        width: 50%;
                        ul {
                            font-size: 1rem;
                            color: #c9c7c7;
                            margin-bottom: 1rem;
    
                            li {
                                padding: 0.5rem 0
                            }
                        }
                        @media screen and (max-width: 727px) {
                            width: 100%;
                            border-bottom: 1px solid #474747 ;
                            margin-bottom: 1.2rem;
                        }
                    }
    
                    .resume {
                        width: 50%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 0 1.2rem;
                        .download {
                            width: 100%;
                            background: #242424;
                            padding: 2rem;
                            text-align: center;
                            font-size: 1rem;
                            &:hover {
                                background-color: white;
                                color: black;
                                transition: all 0.3s ease-out
                            }
                        }
                        @media screen and (max-width: 727px) {
                            width: 100%;
                            padding: 0;
                        }
                    }
                    @media screen and (max-width: 727px) {
                        flex-direction: column;
                        align-items: center;
                        padding: 0;
                    }
                }
                @media screen and (max-width: 727px) {
                    text-align: center;
                }
                @media screen and (max-width: 550px) {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
    @media screen and (max-width: 727px) {
        padding-top: 66px;
        min-height: 666px;
    }

    @media screen and (max-width: 400px) {
        padding-top: 66px;
        min-height: 750px;
    }
}
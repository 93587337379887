#projects {
    background: #ebeeee;
    padding-top: 90px;
    padding-bottom: 60px;

    .container {
        margin: 0 auto;
        width: 95%;
        max-width: 1024px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .section-title {
            width: 100%;
            font-size: 1.4rem;
            font-weight: bold;
            color: #242424;
            margin-bottom: 2rem;
            padding: 0.4rem 0;
            border-bottom: 4px solid #F06000;
        }

        .portfolio-area {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            .cards-item {
                position: relative;
                min-height: 20rem;
                width: 40%;
                display: flex;
                margin: 1rem 1rem;
                border-radius: 10px;
                cursor: pointer;
                &-link {
                    display: flex;
                    flex-flow: column;
                    width: 100%;
                    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
                    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
                    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
                    border-radius: 10px;
                    overflow: hidden;
                    text-decoration: none;
                }

                &-pic-wrap {
                    position: relative;
                    height: 100%;
                    width: 100%;
                    padding-top: 67%;
                    overflow: hidden;
                    margin: 0;
                    &::after {
                        content: attr(data-category);
                        position: absolute;
                        bottom: 0;
                        margin-left: 10px;
                        padding: 6px 10px;
                        max-width: calc((100%) - 30px);
                        font-size: 12px;
                        font-weight: 700;
                        color: #fff;
                        background-color: #F06000;
                        box-sizing: border-box;
                    }
                }

                .fade-img {
                    animation-name: fade-img;
                    animation-duration: 2s;
                }

                &-img {
                    display: none;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.2s linear;
                    &:hover {
                        transform: scale(1.1);
                    }
                }

                &-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px 30px 30px;
                }

                &-text {
                    color: #fff;
                    font-size: 18px;
                    line-height: 24px;
                }

                @media only screen and (min-width: 1024px) {
                    .cards-items {
                        display: flex;
                    }
                }

                @media only screen and (max-width: 1024px) {
                    .cards-item {
                        margin-bottom: 2rem;
                        min-width: 75%;
                    }
                }

                @media screen and (max-width: 807px) {
                    min-width: 80%;
                }
            }
        }
    }
}
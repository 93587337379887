header {
    height: 100vh;
    width: 100%;
    background-image: url('../../images/hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;

    #tsparticles {
        height: 100%;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba($color: #000000, $alpha: 0.3);
        .content-area {
            width: 100%;
            height: 100vh;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .header__banner {
                background-color: rgba($color: #000000, $alpha: 0.8);
                max-width: 40%;
                min-width: 700px;
                height: auto;
                color: white;
                text-align: center;
                padding: 4rem;
                z-index: 50;
                .main-title {
                    font: 90px/1.1em 'opensans-bold', sans-serif;
                    font-weight: bold;
                    color: #fff;
                    letter-spacing: -2px;
                    margin: 0 auto 1.5rem auto;
                    text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
                }

                .main-text {
                    font-size: 1.3rem;
                    margin-bottom: 2rem;
                }

                .external-links {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon {
                        font-size: 2rem;
                        margin: 0 1.5rem;
                        cursor: pointer;
                        color: white;
                        
                        &:hover {
                            color: #F06000;
                            transition: all 0.3s ease-out;
                        }
                    }
                }
                @media screen and (max-width: 770px) {
                    min-width: 95%;
                    padding: 1rem;
                    .main-title {
                        font-size: 5vh;
                    }
    
                    .main-text {
                        font-size: 2vh;
                    }
    
                    .external-links {
                        .icon {
                            font-size: 1.8rem;
                        }
                    }
                }
                @media screen and (max-width: 374px) {
                    min-width: 98%;
                    padding: 2rem 1rem 1rem 1rem;
                    .main-title {
                        font-size: 4vh;
                    }
    
                    .main-text {
                        font-size: 1.5vh;
                    }
    
                    .external-links {
                        .icon {
                            font-size: 1.8rem;
                        }
                    }
                }
            }

            .down-icon {
                position: absolute;
                bottom: 30px;
                left: 50%;
                right: 50%;
                font-size: 2rem;
                color: white;
                cursor: pointer;

                &:hover {
                    color: #F06000;
                    transition: all 0.3s ease-out;
                }
            }
        }
    }
}